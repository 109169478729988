import React from "react"
import { Link } from "gatsby"
import Image from "gatsby-image"

import { rhythm, scale } from "../utils/typography"

const Layout = ({ location, title, image, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  let header

  if (location.pathname === rootPath) {
    header = (
      <>
        <h1
          style={{
            ...scale(1.5),
            marginTop: 0,
            fontFamily: `Dancing Script, sans-serif`,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              color: "#E91E63",
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
        <Image
          fixed={image}
          alt={"Miss M.M."}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 130,
            paddingBottom: 16,
          }}
        />
      </>
    )
  } else {
    header = (
      <h3
        style={{
          fontFamily: `Dancing Script, sans-serif`,
          marginTop: 0,
        }}
      >
        <Link
          style={{
            boxShadow: `none`,
            color: "#E91E63",
          }}
          to={`/`}
        >
          {title}
        </Link>
      </h3>
    )
  }
  return (
    <div
      style={{
        marginLeft: `auto`,
        marginRight: `auto`,
        maxWidth: rhythm(24),
        padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
      }}
    >
      <header
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        {header}
      </header>
      <main>{children}</main>
      <footer>
        © {new Date().getFullYear()}, Made in
        {` `}
        <a href="https://www.wereds.com">Wereds!</a>
      </footer>
    </div>
  )
}

export default Layout
